import React from 'react'
import { useStaticQuery, graphql } from 'gatsby';
import Container from '../components/ui/Container';
import TitleSection from '../components/ui/TitleSection';
import FormatHtml from '../components/utils/FormatHtml';
import Timeline from '../components/ui/Timeline';
import { SectionTitle } from '../helpers/definitions';



interface Experience {
    node: {
      id: string;
      html: React.ReactNode;
      frontmatter: {
        company: string;
        department: string;
        position: string;
        startDate: string;
        endDate: string;
      };
    };
  }

  const Experience: React.FC = () => {

    console.log("jueputavida");
    
    const { markdownRemark, allMarkdownRemark } = useStaticQuery(graphql`
      query {
        markdownRemark(frontmatter: { category: { eq: "experiences section" } }) {
          frontmatter {
            title
            subtitle
          }
        }
        allMarkdownRemark(
          filter: { frontmatter: { category: { eq: "experiences" } } }
          sort: { order: DESC, fields: fileAbsolutePath }
        ) {
          edges {
            node {
              id
              html
              frontmatter {
                company
                department
                position
                startDate
                endDate
              }
            }
          }
        }
      }
    `);
    
  
    const sectionTitle: SectionTitle = markdownRemark.frontmatter;
    const experiences: Experience[] = allMarkdownRemark.edges;
    
    
    return (
    
      <Container section>
        <TitleSection title={sectionTitle.title} subtitle={sectionTitle.subtitle} />
  
        {experiences.map((item) => {
          const {
            id,
            html,
            frontmatter: { company, department, position, startDate, endDate }
          } = item.node;
  
          return (
            <Timeline
              key={id}
              title={company}
              subtitle={position}
              content={<FormatHtml content={html} />}
              startDate={startDate}
              endDate={endDate}
            />
          );
        })}
      </Container>           
    );
  };




export default Experience
