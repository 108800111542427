import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerCV from '../components/BannerCV'
import Experience from '../components/Experience'
import Education from '../components/Education'

const CVPage = (props) => (
    <Layout>
        <Helmet>
            <title>Curriculum Vitae</title>
            <meta name="description" content="Landing Page" />
        </Helmet>

        <BannerCV />
        <Experience/>
        <Education/>

       
    </Layout>
)

export default CVPage