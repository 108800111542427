import React from 'react'

import cvpdf from '../assets/pdf/Camargo_CV.pdf'
const BannerCV = (props) => (
    <section id="bannercv" className="style2">
        <div className="inner">
            <header className="major">
                <h1>Curriculum Vitae</h1>
            </header>
            <div className="content">
                <p>This is just glance. To get more details,<br />
                <a href={cvpdf}>Download</a> the full CV as PDF, <br/>
                or better, <a href="mailto:jon-cama@gatech.edu" >Contact me!</a>            
                </p>
            </div>
        </div>
    </section>
)

export default BannerCV
